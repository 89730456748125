import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LocationListGroup from "../components/locations/location-list-group";
import Seo from "../components/seo";
import Logo from "../images/toyota/logo-400.png";
import Photo from "../images/toyota/russ.jpg";
import HowToDonateSection from "../sections/how-to-donate-section";
import QuoteSection from "../sections/quote-section";
import AboutSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Toyota" mdxType="Seo" />
  <QuoteSection logo={Logo} photo={Photo} text="Toyota and its dealers are honored to take part in the KGW Great Toy Drive again this holiday season. Please join us in bringing joy to thousands of kids across Oregon and Southwest Washington by bringing a new, unwrapped toy to one of our 13 participating Toyota dealerships. As we come back together this holiday season, let’s do our best to make sure that the Holidays are filled with love, happiness, and gifts for all children." name="Russ Humberston" title="Pacific Northwest Toyota Dealers Association President" mdxType="QuoteSection" />
  <VideoSection videos={[{
        file: "toyota-duplicator-30.mp4"
      }, {
        file: "toyota-surprise-30.mp4"
      }, {
        file: "regence-60.mp4"
      }, {
        file: "iq-toy-bot-30.mp4"
      }, {
        file: "fred-meyer-30.mp4"
      }, {
        file: "iq-surprise-30.mp4"
      }]} mdxType="VideoSection" />
  <LocationListGroup lists={[{
        title: false,
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/toyota-locations.json",
        id: "toyota-locations",
        note: true
      }]} headline="Visit Your Local Toyota Dealer" map={false} mdxType="LocationListGroup" />
  <HowToDonateSection mdxType="HowToDonateSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      